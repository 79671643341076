<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2023-02-18 21:33:04
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-21 16:18:10
-->
<template>
  <el-card class="box query">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus" v-if="is_can_edit">新增</el-button>
  </el-card>
  <el-card class="box data">
    <el-table :data="roles" v-loading="loading" border>
      <!-- <el-table-column label="编号" prop="id" width="100"></el-table-column> -->
      <el-table-column label="名称" prop="name" width="360"></el-table-column>
      <el-table-column label="描述" prop="description">
        <!-- <template #default="item">
          <el-tag type="success" v-if="item.row.iis_allot">已配置</el-tag>
          <el-button
            size="small"
            @click="onSetFunction(item.row, app)"
            v-for="app in apps"
            :key="app.key"
            >配置[{{ app.name }}]权限</el-button
          >
        </template>-->
      </el-table-column>
      <el-table-column label="创建时间" prop="creationTime" width="240"></el-table-column>
      <el-table-column label="操作" width="150">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)" icon="edit" v-if="is_can_edit">修改</el-button>
          <!-- <el-button type="text" class="btn-del" size="small" @click="onEdit(scope.row)"
          >删除</el-button
          >-->
          <el-popconfirm title="确定要删除？" @confirm="onDelete(scope.row)" v-if="is_can_del">
            <template #reference>
              <el-button type="danger" link size="small" icon="delete">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <edit-role :item="current_item" @success="loadData"></edit-role>
</template>

<script>
import role_api from "@/http/role_api";
import EditRole from "./edit_role.vue";
export default {
  components: {
    EditRole,
  },
  data() {
    return {
      loading: false,
      roles: [],
      current_item: null,
      is_can_edit: false,
      is_can_del: false,
    };
  },
  created() {
    this.is_can_edit = this.$power("admin/v1/role/edit");
    this.is_can_del = this.$power("admin/v1/role/del");
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      role_api.get_roles().then((res) => {
        if (res.code == 0) {
          this.roles = res.data;
        }
      });
    },

    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current_item = Object.assign({}, item);
    },

    /**
     * 删除
     */
    onDelete(item) {
      console.log(item);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>