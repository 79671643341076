/*
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2023-03-21 14:55:41
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-12-20 15:57:19
 */
import http from "./axios_init";

export default {
  /**
   * 获取所有的角色
   * @returns
   */
  get_roles() {
    return http.get("admin/v1/role");
  },

  /**
   * 获取详情
   * @param {*} id 
   * @returns 
   */
  get_dtl(id){
    return http.get('admin/v1/role/dtl?id='+id);
  },

  /**
   * select 下拉数据
   * @returns 
   */
  get_select(){
    return http.get("admin/v1/role/get_select");
  }

};
