<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2023-03-21 14:58:56
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-11 15:35:06
-->
<template>
  <el-drawer
    v-model="drawer"
    :title="title"
    direction="rtl"
    size="800px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    @closed="onClosed"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="名称" prop="name">
        <el-col :span="14">
          <el-input v-model="form.name" placeholder="请输入角色名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="描述">
        <el-col :span="20">
          <el-input v-model="form.description" type="textarea" placeholder></el-input>
        </el-col>
      </el-form-item>
    </el-form>
    <el-divider>权限策略</el-divider>
    <div class="power_wrp">
      <el-scrollbar>
        <el-tree
          ref="tree"
          :data="powers"
          show-checkbox
          node-key="id"
          default-expand-all
          check-strictly
        ></el-tree>
      </el-scrollbar>
    </div>
    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="form.saving">确定</el-button>
    </template>
  </el-drawer>
</template>

<script>
import power_api from "@/http/power_api";
import role_api from "@/http/role_api";

export default {
  data() {
    return {
      drawer: false,
      title: "编辑角色",
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入角色名称",
            trigger: "blur",
          },
        ],
      },
      powers: [],
    };
  },
  props: ["item"],
  emits: ["success"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          this.form = {};
          this.form = Object.assign({}, this.item);
          if (this.form.id) {
            this.loadDtl();
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.loadPower();
  },
  methods: {
    /**
     *  加载详情
     */
    loadDtl() {
      role_api.get_dtl(this.form.id).then((res) => {
        if (res.code == 0) {
          this.form.powerIds = res.data.powerIds;
          this.$refs.tree.setCheckedKeys(this.form.powerIds, true);
        }
      });
    },

    /**
     * 加载权限策略
     */
    loadPower() {
      power_api.get_tree(this.$cfg.APP_CODE).then((res) => {
        if (res.code == 0) {
          this.powers = res.data;
        }
      });
    },

    /**
     * 关闭时清除所有选择
     */
    onClosed() {
      this.$refs.tree.setCheckedKeys([], false);
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.saving = true;

          var model = {
            id: this.form.id,
            name: this.form.name,
            description:this.form.description,
            powerIds: this.$refs.tree.getCheckedNodes().map((x) => x.id),
          };

          this.$http
            .post("/admin/v1/role/edit", model)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("success");
              }
            })
            .finally(() => {
              this.form.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.power_wrp {
  flex: 1;
  height: 1px;
}
</style>